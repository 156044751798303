import {
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  USER_LOADED,
  USER_LOADING,
  LOGOUT_SUCCESS,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";
const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  isLoading: true,
  user: null,
  error: false,
  needsVerification: false,
};

const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(USER_LOADED, (state, { payload }) => {
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("user", JSON.stringify(payload.user));
      state.isAuthenticated = true;
      state.isLoading = false;
      state.user = payload.user;
      state.token = payload.token;
      state.error = false;
    })
    .addCase(USER_LOADING, (state) => {
      state.isLoading = true;
      state.error = false;
    })
    .addCase(LOGIN_SUCCESS, (state, { payload }) => {
      localStorage.setItem("token", payload.token);
      localStorage.setItem("user", JSON.stringify(payload.user));
      localStorage.setItem("isLoggedIn", true);
      state.isAuthenticated = true;
      state.isLoading = false;
      state.user = payload.user;
      state.token = payload.token;
      state.error = false;
    })
    .addCase(AUTH_ERROR, (state, { payload }) => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("fbToken");
      localStorage.setItem("isLoggedIn", false);
      localStorage.removeItem("isLoggedIn");
      state.isAuthenticated = false;
      state.isLoading = false;
      state.user = null;
      state.token = null;
      state.error = payload;
    })
    .addCase(LOGIN_FAIL, (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("fbToken");
      localStorage.setItem("isLoggedIn", false);
      localStorage.removeItem("isLoggedIn");
      state.isAuthenticated = false;
      state.isLoading = false;
      state.user = null;
      state.token = null;
      state.error = false;
    })
    .addCase(LOGOUT_SUCCESS, (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("fbToken");
      localStorage.setItem("isLoggedIn", false);
      localStorage.removeItem("isLoggedIn");
      state.isAuthenticated = false;
      state.isLoading = false;
      state.user = null;
      state.token = null;
      state.error = false;
    });
});

export default authReducer;
