import {
  GET_PROMO_CODES_USAGE,
  PROMO_CODES_USAGE_ERROR,
  PROMO_CODESUSAGE_LOADING,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
};

const promoCodeUsageReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(PROMO_CODESUSAGE_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(PROMO_CODES_USAGE_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_PROMO_CODES_USAGE, (state, { payload }) => {
      state.data = payload;
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
      state.count = payload.count;

      state.loading = false;
    });
});

export default promoCodeUsageReducer;
