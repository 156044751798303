export const APP_CONFIG = "APP_CONFIG";

export const USER_LOADED = "USER_LOADED";
export const USER_LOADING = "USER_LOADING";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

///Users
export const SET_CURRENT_USERS = "SET_CURRENT_USERS";
export const CHANGE_USER_STATUS = "CHANGE_USER_STATUS";
export const UPDATE_USER = "UPDATE_USER";

export const CREATE_USER = "CREATE_USER";

/// nOTIFICATIONS
export const LIST_NOTIFICATIONS = "LIST_NOTIFICATIONS";
export const LIST_NOTIFICATIONS_LOADING = "LIST_NOTIFICATIONS_LOADING";
export const LIST_NOTIFICATIONS_ERROR = "LIST_NOTIFICATIONS_ERROR";
export const INSERT_NEW_NOTIFICATION = "INSERT_NEW_NOTIFICATION";
export const UPDATE_UN_READ = "UPDATE_UN_READ";

//Categories
export const CATEGORIES_LOADING = "CATEGORIES_LOADING";
export const CATEGORIES_ERROR = "CATEGORIES_ERROR";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const CREATE_CATEGORY = "CREATE_CATEGORY";

//Promo Code
export const CREATE_PROMO_CODE = "CREATE_PROMO_CODE";
export const GET_PROMO_CODES = "GET_PROMO_CODES";
export const PROMO_CODES_ERROR = "PROMO_CODES_ERROR";
export const PROMO_CODES_LOADING = "PROMO_CODES_LOADING";
export const EDIT_PROMO_CODE = "EDIT_PROMO_CODE";

//Product
export const CREATE_PRODUCT = "CREATE_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const PRODUCTS_ERROR = "PRODUCTS_ERROR";
export const PRODUCTS_LOADING = "PRODUCTS_LOADING";

//Brand
export const BRAND_LOADING = "ORDERS_LOADING";
export const BRAND_ERROR = "ORDERS_ERROR";
export const GET_BRANDS = "GET_ORDERS";
export const EDIT_BRAND = "EDIT_BRAND";
export const DELETE_BRAND_MEDIA = "DELETE_BRAND_MEDIA";

// Order
export const ORDERS_LOADING = "ORDERS_LOADING";
export const ORDERS_ERROR = "ORDERS_ERROR";
export const GET_ORDERS = "GET_ORDERS";

// contact us
export const CONTACTS_LOADING = "CONTACTS_LOADING";
export const CONTACTS_ERROR = "CONTACTS_ERROR";
export const GET_CONTACTS = "GET_CONTACTS";

// promo code usage
export const PROMO_CODESUSAGE_LOADING = "PROMO_CODESUSAGE_LOADING";
export const PROMO_CODES_USAGE_ERROR = "PROMO_CODESUSAGE_ERROR";
export const GET_PROMO_CODES_USAGE = "GET_PROMO_CODES_USAGE";

//Home Carousels
export const HOME_CAROUSEL_LOADING = "HOME_CAROUSEL_LOADING";
export const HOME_CAROUSEL_ERROR = "HOME_CAROUSEL_ERROR";
export const GET_HOME_CAROUSELS = "GET_HOME_CAROUSELS";
export const CREATE_HOME_CAROUSEL = "CREATE_HOME_CAROUSEL";
export const EDIT_HOME_CAROUSEL = "EDIT_HOME_CAROUSEL";
export const DELETE_HOME_CAROUSEL = "DELETE_HOME_CAROUSEL";
