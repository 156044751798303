import {
  BRAND_LOADING,
  BRAND_ERROR,
  GET_BRANDS,
  EDIT_BRAND,
  DELETE_BRAND_MEDIA,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
};

const brandReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(BRAND_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(BRAND_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_BRANDS, (state, { payload }) => {
      state.data = payload?.brands;
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
      state.count = payload.count;
      state.loading = false;
    })
    .addCase(EDIT_BRAND, (state, { payload }) => {
      const index = state.data?.findIndex((e) => e?.id === payload?.id);
      state.data[index] = payload;
      state.loading = false;
      state.error = false;
    })
    .addCase(DELETE_BRAND_MEDIA, (state, { payload }) => {
      const index = state.data?.findIndex((e) => e?.id === payload?.brandId);
      state.data[index].media = state.data[index].media?.filter(
        (e) => e?.id !== payload?.brandMediaId
      );
      state.loading = false;
      state.error = false;
    });
});

export default brandReducer;
