import {
  PROMO_CODES_LOADING,
  PROMO_CODES_ERROR,
  GET_PROMO_CODES,
  CREATE_PROMO_CODE,
  EDIT_PROMO_CODE,
} from "../actions/types";
import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  error: "",
  loading: false,
  data: [],
  thisPage: 0,
  nextPage: 0,
  hasMore: true,
  count: 0,
};

const promoCodesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(PROMO_CODES_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(PROMO_CODES_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_PROMO_CODES, (state, { payload }) => {
      state.data = payload?.promoCodes;
      state.thisPage = payload.thisPage;
      state.nextPage = payload.nextPage;
      state.hasMore = payload.hasMore;
      state.count = payload.count;
      state.loading = false;
    })
    .addCase(CREATE_PROMO_CODE, (state, { payload }) => {
      state.data.unshift(payload);
      state.loading = false;
      state.error = false;
    })
    .addCase(EDIT_PROMO_CODE, (state, { payload }) => {
      const index = state.data?.findIndex((e) => e?.id === payload?.id);
      state.data[index] = payload;
      state.loading = false;
      state.error = false;
    });
});

export default promoCodesReducer;
