import { createReducer } from "@reduxjs/toolkit";
import {
  CREATE_HOME_CAROUSEL,
  DELETE_HOME_CAROUSEL,
  EDIT_HOME_CAROUSEL,
  GET_HOME_CAROUSELS,
  HOME_CAROUSEL_ERROR,
  HOME_CAROUSEL_LOADING,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const homeCarouselReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(HOME_CAROUSEL_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(HOME_CAROUSEL_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_HOME_CAROUSELS, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    })
    .addCase(CREATE_HOME_CAROUSEL, (state, { payload }) => {
      state.data.unshift(payload);
      state.loading = false;
    })
    .addCase(EDIT_HOME_CAROUSEL, (state, { payload }) => {
      const index = state.data?.findIndex((e) => e?.id === payload?.id);
      state.data[index] = payload;
      state.loading = false;
      state.error = false;
    })
    .addCase(DELETE_HOME_CAROUSEL, (state, { payload }) => {
      state.data = state.data.filter((e) => e?.id !== payload?.id);
      state.loading = false;
      state.error = false;
    });
});

export default homeCarouselReducer;
