// errorSlice.ts
import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    errorMessage: null,
    successMessage: null, // Initialize success message to null
};

const alertSlice = createSlice({
    name: "alert",
    initialState,
    reducers: {
        setError(state, action) {
            state.errorMessage = action.payload;
        },
        setSuccessMessage(state, action) {
            // Add setSuccessMessage reducer
            state.successMessage = action.payload;
        },
        clearError(state) {
            state.errorMessage = null;
        },
        clearSuccessMessage(state) {
            // Add clearSuccessMessage reducer
            state.successMessage = null;
        },
    },
});

export const { setError, setSuccessMessage, clearError, clearSuccessMessage } =
    alertSlice.actions;


export default alertSlice.reducer;
